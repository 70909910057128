import React, { useState } from "react";
import logoImg from "../../assets/logo.png";
import { Button, Form, Input, message } from "antd";
import "./ForgotPassword.scss";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { sendOtpAPI,VerifyAPI,ForgotPasswordAPI } from "../../services/Api/Api";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const roleid = localStorage.getItem("role_id");
  const headers = {
    role_id: roleid,
  };
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");

  const [step, setStep] = useState(1);

  const handleSendOtp = async (values) => {
    const formData = {
      email: values?.email,
      type: "forgot_password",
    };

    sendOtpAPI(formData, headers)
      .then((res) => {
        message.success("OTP sent to your email!");
        setEmail(values.email);
        setStep(2);
      })
      .catch((e) => {
        console.log(e, "error");
      });

    // try {
    //   sendOtpAPI(formData,headers)
    //   message.success("OTP sent to your email!");
    //   setEmail(values.email);
    //   setStep(2);
    // } catch (error) {
    //   message.error("Failed to send OTP!");
    // }
  };

  const handleVerifyOtp = async () => {
    const formData = {
      email: email,
      otp: otp,
      otp_type: "forgot_password",
    };

    VerifyAPI(formData, headers)
      .then((res) => {
        message.success(res?.data?.message);
        // navigate("/login")
        setToken(res?.data?.data?.token);
        setStep(3);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePasswordChange = async (values) => {
    const formData = {
      email: email,
      password: values.password,
      confirm_password: values?.confirmPassword,
      token: token,
    };

    ForgotPasswordAPI(formData, headers)
      .then((res) => {
        message.success(res?.data?.message);
        navigate("/login");
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  return (
    <div className="ForgotPassword-page">
    <div className="ForgotPassword-container">
      <div className="login-form">
        <img src={logoImg} width={150} alt="logo" />
        <div className="heading">Forgot Password!</div>

        {step === 1 && (
          <div className="SendEmail">
            <div className="form">
              <Form
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
                onFinish={handleSendOtp}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input
                    className="input_custom"
                    placeholder="example123@gmail.com"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Send Otp
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="VerifyOtp">
            <div className="form">
              <Form
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
                onFinish={handleVerifyOtp}
              >
                <Form.Item>
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{
                          width: "100%",
                          height: "40px",
                          fontSize: "18px",
                          textAlign: "center",
                          color: "black",
                          background: "#fff",
                          border: "2px solid black",
                          borderRadius: "5px",
                        }}
                      />
                    )}
                  />
                </Form.Item>
                <Form.Item>
                  <Button className="btn_theme" htmlType="submit" block>
                    Verify OTP
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="forgot-password">
            <div className="form">
              <Form
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
                onFinish={handlePasswordChange}
              >
                <Form.Item
                  label="Create Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                    {
                      min: 8,
                      message: "Password must be at least 8 characters long",
                    },
                    {
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                      message:
                        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                    },
                  ]}
                >
                  <Input.Password className="inputs" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password className="inputs" />
                </Form.Item>
                <Form.Item>
                  <Button className="btn_theme" htmlType="submit" block>
                    Change Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default ForgotPassword;
